<template>
        
  <div class="app-container">
      
    <el-form label-width="120px">
      <el-form-item label="选择Excel">
        <el-upload
          ref="dragupload"
          :auto-upload="false"
          :on-success="fileUploadSuccess"
          :on-error="fileUploadError"
          :disabled="importBtnDisabled"
          :limit="1"
          :headers="mytoken"
          :action="'/api/jd/all/lg/import'"
          :data="uploadData"
          :on-remove="handleRemove"
          name="file">
          <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
          <el-button
            :loading="loading"
            style="margin-left: 10px;"
            size="small"
            type="success"
            @click="submitUpload">灵感订单导入</el-button>
        </el-upload>
      </el-form-item>
    </el-form>

    <el-input v-model="cookie" placeholder="请输入灵感cookie" style="width:240px"></el-input>
    <el-button type="primary"  @click="addCookie(cookie)" >添加灵感cookie</el-button>
</div>
  
</template>
  
  <script>
   import { addCookie } from "@/api/lgorder";
  export default {
    data() { //定义变量和初始值
      return{
        importBtnDisabled: false, // 按钮是否禁用,
        loading: false,
         cookie: '',
      }
    },
    computed: {
    mytoken() {
      return {
        "x-auth-token": localStorage.getItem("token"),
      };
    },
    uploadData() {
      return {
      };
    },
    uploadLgData() {
      return {
      };
    },
    
  },
    created() { //页面渲染之前执行
     
    },
    methods: {
 async addCookie(val){
        await addCookie({cookie: val})
          .then((res) => {
            if (res.code == 1000) {
               this.$message({
            message: "添加成功",
            type: "success",
          });
          } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }

      })
    },

     //点击按钮上传文件到接口里面
        submitUpload() {
            this.importBtnDisabled = true
            this.loading = true
            this.$refs.dragupload.submit();
        },
        //上传成功
        fileUploadSuccess(response) {
            //提示信息
            this.loading = false
            this.importBtnDisabled = false
              if (response.code == 1000) {
                this.$message({
                            type: 'success',
                            message: '上传成功'
                        })
              }
        },
        //上传失败
        fileUploadError() {
            this.loading = false
            this.importBtnDisabled = false
            this.$message({
                type: 'error',
                message: '失败'
            })
        }
    }
  }
  </script>
  
  
